import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles'
import { withPrefix } from 'gatsby'

import theme from '../theme'

import Header from '../components/header'
import Footer from '../components/footer'

const showHeader = true

const GlobalCss = withStyles({
  '@global': {
    'html, body, #___gatsby, #gatsby-focus-wrapper': {
      height: '100%',
    },
  },
})(() => null)

const useStyles = makeStyles({
  site: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  siteContent: {
    flexGrow: 1,
  },
  header: {
    flexShrink: 0,
  },
  footer: {
    flexShrink: 0,
  },
})

// https://twitter.com/joshwcomeau/status/1213870628895428611?s=12
const faviconVersion = 2

export default function TopLayout({ children, location }) {
  const classes = useStyles()

  // If location is undefined, we're probably in the preview: assume we're home.
  const isHome = !location || location.pathname === '/'

  return (
    <React.Fragment>
      <Helmet>
        <html lang="sv" />
        <title>Verano Vokalensemle</title>
        <meta
          name="description"
          content="Verano Vokalensemble | Projektkör verksam i Uppsala och Stockholm med omnejd"
        />

        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=EB+Garamond:400,500,700&display=swap"
          rel="stylesheet"
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix(
            '/'
          )}img/apple-touch-icon.png?v=${faviconVersion}`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png?v=${faviconVersion}`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png?v=${faviconVersion}`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix(
            '/'
          )}img/safari-pinned-tab.svg?v=${faviconVersion}`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalCss />
        <div className={classes.site}>
          {showHeader && <Header className={classes.header} isHome={isHome} />}
          <div className={classes.siteContent}>{children}</div>
          <Footer className={classes.footer} />
        </div>
      </ThemeProvider>
    </React.Fragment>
  )
}

TopLayout.propTypes = {
  children: PropTypes.node,
}

import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

const headingFontFamily = [
  '"EB Garamond"',
  'serif',
  '"Roboto"',
  '"Helvetica"',
  '"Arial"',
  'sans-serif',
].join(',')

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ff5633',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    h1: {
      fontFamily: headingFontFamily,
    },
    h2: {
      fontFamily: headingFontFamily,
    },
    h3: {
      fontFamily: headingFontFamily,
    },
    h4: {
      fontFamily: headingFontFamily,
    },
  },
})

export default theme

import React from 'react'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import WrenchIcon from '@material-ui/icons/Build'
import ForwardOutboundLink from './ForwardOutboundLink'

export default function Copyright() {
  return (
    <>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        paragraph
      >
        {'Copyright © '}
        Verano {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <MuiLink
          color="inherit"
          component={ForwardOutboundLink}
          href="https://eliasnorrby.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <WrenchIcon fontSize="inherit" color="inherit" />
        </MuiLink>
      </Typography>
    </>
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Copyright from './copyright'

const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Copyright />
    </footer>
  )
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}))

export default Footer

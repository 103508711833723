import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

import MusicIcon from '@material-ui/icons/MusicNote'
import BackIcon from '@material-ui/icons/ArrowBack'

import { makeStyles } from '@material-ui/core/styles'

import { Link } from 'gatsby'

const Header = ({ isHome }) => {
  const classes = useStyles()
  const Icon = isHome ? MusicIcon : BackIcon
  return (
    <AppBar position="relative" color="transparent">
      <Toolbar>
        <IconButton
          className={classes.icon}
          color="inherit"
          component={Link}
          to="/"
        >
          <Icon />
        </IconButton>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          component={Link}
          to="/"
          className={classes.link}
        >
          Verano
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
}))

export default Header
